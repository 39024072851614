<template>
    <text-page />
</template>
<script>
export default {
  name: 'imprint',
  components: {
    textPage: () => import('@/components/text-page.vue')
  }
}
</script>
